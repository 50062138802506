/* eslint-disable*/
import VueStoreService from './VueStoreService';
const LessonService = {
    async getLessons(filter,store){
        var resp = await VueStoreService.get("lesson"+filter, store)
        return resp;
    },
    async getInbox(filter,store){
        var resp = await VueStoreService.get("lesson/inbox"+filter, store)
        return resp;
    },
    async getInboxBubble(filter){
        var resp = await VueStoreService.get2("lesson/inbox"+filter)
        return resp;
    },
      //CAMBIO DE ESTADO
      async saveChangeStatus(data,store){
        var resp = await VueStoreService.post("lesson/status_masive",data,store)
        return resp;
    },

    async saveLesson(data,store){
        var resp = await VueStoreService.post("lesson",data,store)
        return resp;
    },
    async getLessonById(lesson_id,store){
        var resp = await VueStoreService.get( "lesson/"+lesson_id,store)
        return resp;
    },
    async getCommentsByid(lesson_id,store){
        var resp = await VueStoreService.get( "lesson/comments/"+lesson_id,store)
        return resp;
    },
    async updateLesson(lesson_id,data,store){
        var resp = await VueStoreService.put("lesson/"+lesson_id,data,store)
        return resp;
    },

    async deleteLesson(lesson_id,store){
        var resp = await VueStoreService.delete("lesson/"+lesson_id,store)
        return resp;
    },

    //files
    async getFiles(filter,store){
        var resp = await VueStoreService.get(("attachment_lesson"+filter), store)
        return resp;
    },
    async saveFile(data,store){
        var resp = await VueStoreService.post("attachment_lesson",data,store)
        return resp;
    },
    async getFileById(file_id,store){
        var resp = await VueStoreService.get( "attachment_lesson/"+file_id,store)
        return resp;
    },

    async deleteFile(file_id,store){
        var resp = await VueStoreService.delete("attachment_lesson/"+file_id,store)
        return resp;
    },
    //EXPORTAR
    async exportExcel(store){
        var resp = await VueStoreService.getFile("lesson/download/now",store)
        return resp;
    },
    async exportExcelFormat(id,store){
        var resp = await VueStoreService.getFile("lesson/download/"+id,store)
        return resp;
    },
}

export default LessonService;