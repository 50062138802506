/* eslint-disable */
export default [
  {
    icon: 'FolderIcon',
    children: [
      {
        id: 'mantenedor-area',
        title: 'Area',
        route: 'areas',       
      },
      {
        id: 'mantenedor-categoria',
        title: 'Categoria',
        route: 'categoria'
      },
      {
        id: 'mantenedor-sub-categoria',
        title: 'Sub Categoria',
        route: 'sub-categoria'
      },
      {
        id: 'mantenedor-co-dueño',
        title: 'Co-Dueño',
        route: 'co-dueño'
      },
      {
        id: 'mantenedor-dueño',
        title: 'Dueño',
        route: 'dueño'
      },
      {
        id: 'mantenedor-especialidades',
        title: 'Especialidades',
        route: 'especialidades'
      },
      {
        id: 'mantenedor-localizacion',
        title: 'Localización',
        route: 'localizacion'
      },
      {
        id: 'mantenedor-actividad',
        title: 'Actividad',
        route: 'actividad'
      },
      {
        id: 'mantenedor-proyectos',
        title: 'Proyectos',
        route: 'proyectos'
      },
      {
        id: 'mantenedor-usuarios',
        title: 'Usuarios',
        route: 'usuarios'
      },
      {
        id: 'mantenedor-usuarios-corporativo',
        title: 'Usuarios',
        route: 'usuarios-corporativo'
      },
      {
        id: 'mantenedor-usuarios-lecciones',
        title: 'Usuarios Lecciones Aprendidas',
        route: 'usuarios-lecciones'
      },
      {
        id: 'mantenedor-periodos',
        title: 'Periodos',
        route: 'periodos'
      },
      {
        id: 'mantenedor-division',
        title: 'Divisiones',
        route: 'division'
      },
      {
        id: 'mantenedor-tipo-obra',
        title: 'Tipo de Obras',
        route: 'tipo-obra'
      },
      {
        id: 'mantenedor-especialidades-lecciones',
        title: 'Especialidades de Lecciones',
        route: 'especialidades-lecciones'
      },
      {
        id: 'mantenedor-tipificacion',
        title: 'Tipificaciones',
        route: 'tipificacion'
      },
      {
        id: 'mantenedor-agrupaciones',
        title: 'Agrupaciones',
        route: 'agrupaciones',
      },
    ]
  },
  {
    icon: 'StopCircleIcon',
    id: 'template-especialidades',
    title: 'Especializades',
    route: 'template-especialidades'
  },  
  {
    icon: 'StopCircleIcon',
    id: 'template-categoria',
    title: 'categoria',
    route: 'template-categoria'
  },  
  {
    icon: 'StopCircleIcon',
    id: 'template-sub-categoria',
    title: 'Sub Categoria',
    route: 'template-sub-categoria'
  },  
  {
    icon: 'StopCircleIcon',
    id: 'template-areas',
    title: 'Areas',
    route: 'template-areas'
  },  
  {
    header: 'Consultas CoDueños',
    icon: 'FolderIcon',
    id: 'consultas-cowner',
    title: 'Consultas CoDueños',
    route: 'consultas-cowner',
  },
  {
    header: 'Consultas',
    icon: 'FolderIcon',
    id: 'consultas',
    title: 'Consultas',
    route: 'consultas',
  },
  {
    header: 'Alertas',
    icon: 'FolderIcon',
    id: 'alertas',
    title: 'Alertas',
    route: 'alertas',
  },
  {
    header: 'Restricciones',
    icon: 'FolderIcon',
    id: 'restricciones',
    title: 'Restricciones',
    route: 'restricciones',
  },
  {
    icon: 'StopCircleIcon',
    id: 'restricciones-liberar-2',
    title: 'Restricciones Por Liberar 2',
    route: 'restricciones-liberar-2'
  },  
  {
    header: 'Restricciones Por Liberar',
    icon: 'FolderIcon',
    id: 'restricciones-liberar',
    title: 'Restricciones Por Liberar',
    route: 'restricciones-liberar',
  },
  {
    header: 'Provisionales',
    icon: 'FolderIcon',
    id: 'provisionales',
    title: 'Provisionales',
    route: 'provisionales',
  },
  {
    header: 'RFI',
    icon: 'FolderIcon',
    id: 'rfi',
    title: 'RFI',
    route: 'rfi',
  },
  {
    header: 'Lecciones',
    icon: 'FolderIcon',
    id: 'lecciones',
    title: 'Lecciones',
    route: 'lecciones',
  },
  {
    header: 'Lecciones Inbox',
    icon: 'FolderIcon',
    id: 'lecciones-inbox',
    title: 'Bandeja Lecciones Aprendidas',
    route: 'lecciones-inbox',
  },
  {
    header: 'Reportes',
    icon: 'FolderIcon',
    id: 'reportes',
    title: 'Reportes',
    route: 'reportes',
  },
  {
    header: 'Scheduler',
    icon: 'FolderIcon',
    id: 'scheduler',
    title: 'Scheduler',
    route: 'scheduler',
  },
  {
    header: 'Tareas Programadas Codueños',
    icon: "FolderIcon",
    id: "scheduler-cowner",
    title: "Tareas Programadas Codueños",
    route: "scheduler-cowner",
  },
  {
    header: '"Lista de Alertas Co Dueño',
    icon: "FolderIcon",
    id: "alertas-cowner",
    title: "Lista de Alertas Co Dueño",
    route: "alertas-cowner",
  },    
  {
    header: 'Restricciones Agregar',
    icon: 'FolderIcon',
    id: 'restricciones-registro',
    title: 'Restricciones Agregar',
    route: 'restricciones-registro',
    hide: true,
  },
  {
    header: 'Restricciones Editar',
    icon: 'FolderIcon',
    id: 'restricciones-editar',
    title: 'Restricciones Editar',
    route: 'restricciones-editar',
    hide: true,
  },
  {
    header: 'Restricciones Vista',
    icon: 'FolderIcon',
    id: 'restricciones-vista',
    title: 'Restricciones Vista',
    route: 'restricciones-vista',
    hide: true,
  },
  {
    header: 'Restricciones Importar',
    icon: 'FolderIcon',
    id: 'restricciones-importar-datos',
    title: 'Restricciones Importar',
    route: 'restricciones-importar-datos',
    hide: true,
  },
  
  {
    header: 'Provisional Agregar',
    icon: 'FolderIcon',
    id: 'provisionales-registro',
    title: 'provisionales Agregar',
    route: 'provisionales-registro',
    hide: true,
  },
  {
    header: 'Provisional Editar',
    icon: 'FolderIcon',
    id: 'provisionales-editar',
    title: 'provisionales Editar',
    route: 'provisionales-editar',
    hide: true,
  },
  {
    header: 'Provisional Completar',
    icon: 'FolderIcon',
    id: 'provisionales-completar',
    title: 'provisionales Completar',
    route: 'provisionales-completar',
    hide: true,
  },
  {
    header: 'Provisional Importar',
    icon: 'FolderIcon',
    id: 'provisionales-importar-datos',
    title: 'provisionales Importar',
    route: 'provisionales-importar-datos',
    hide: true,
  },
  //RFI
  {
    header: 'RFI Agregar',
    icon: 'FolderIcon',
    id: 'rfi-registro',
    title: 'RFI Agregar',
    route: 'rfi-registro',
    hide: true,
  },
  {
    header: 'RFI Editar',
    icon: 'FolderIcon',
    id: 'rfi-editar',
    title: 'RFI Editar',
    route: 'rfi-editar',
    hide: true,
  },
  {
    header: 'RFI Importar',
    icon: 'FolderIcon',
    id: 'rfi-importar-datos',
    title: 'RFI Importar',
    route: 'rfi-importar-datos',
    hide: true,
  },
  {
    header: 'Actividades Importar',
    icon: 'FolderIcon',
    id: 'mantenedores-actividad-importar-datos',
    title: 'Actividades Importar',
    route: 'mantenedores-actividad-importar-datos',
    hide: true,
  },


  {
    header: 'Lección Agregar',
    icon: 'FolderIcon',
    id: 'lecciones-registro',
    title: 'lecciones Agregar',
    route: 'lecciones-registro',
    hide: true,
  },
  {
    header: 'Lección Editar',
    icon: 'FolderIcon',
    id: 'lecciones-editar',
    title: 'lecciones Editar',
    route: 'lecciones-editar',
    hide: true,
  },
  {
    header: 'Lección Vista',
    icon: 'FolderIcon',
    id: 'lecciones-vista',
    title: 'Lección Vista',
    route: 'lecciones-vista',
    hide: true,
  },
  {
    icon: 'StopCircleIcon',
    id: 'power-bi',
    title: 'Power BI',
    route: 'power-bi',
  },
  {
    icon: 'StopCircleIcon',
    id: 'graph-projects',
    title: 'Dashboard',
    route: 'graph-projects',
  },
   
  //seleccionar
  {
    header: 'Seleccionar Proyecto',
    icon: 'FolderIcon',
    id: 'seleccionar-proyecto',
    title: 'Seleccionar Proyecto',
    route: 'seleccionar-proyecto',
    hide: true,
  },
  //postLogueo
  {
    header: 'Seleccionar Tipo de Logueo',
    icon: 'FolderIcon',
    id: 'seleccionar-log-type',
    title: 'Seleccionar Tipo de Logueo',
    route: 'seleccionar-log-type',
    hide: true,
  },
]
