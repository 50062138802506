/* eslint-disable*/
import VueStoreService from './VueStoreService';
const UserService = {
    async getUsers(url,store){
        var resp = await VueStoreService.get("user"+url, store)
        return resp;
    },  
    async getUsersLessons(url,store){
        var resp = await VueStoreService.get("user/userLessons"+url, store)
        return resp;
    },  
    async getUserRole(user_id,store){
        var resp = await VueStoreService.get("user/getRole/" + user_id, store)
        return resp;
    },  
    async saveUser(data,store){
        var resp = await VueStoreService.post("user",data,store)
        return resp;
    },
    async saveMultiUser(data,store){
        var resp = await VueStoreService.post("user/multi-project",data,store)
        return resp;
    },
    async validateUser(data,store){
        var resp = await VueStoreService.post("user/validateUser",data,store)
       
        return resp;
    },
    async validateDeleteUser(user_id,store){
        var resp = await VueStoreService.get("user/canDeleteUser/" + user_id,store)
        return resp;
    },
    async getUser(user_id,store){
        var resp = await VueStoreService.get( "user/"+user_id,store)
        return resp;
    },
    async updateUser(user_id,data,store){
        var resp = await VueStoreService.put("user/"+user_id,data,store)
        return resp;
    },
    async updateUserLesson(user_id,data,store){
        var resp = await VueStoreService.put("user/lesson/"+user_id,data,store)
        return resp;
    },
    async updateUserMultiple(user_id,data,store){
        var resp = await VueStoreService.put("user/multi-project/"+user_id,data,store)
        return resp;
    },

    async deleteUser(user_id,project_id,store){
        var resp = await VueStoreService.delete("user/"+user_id+'/'+project_id,store)
        return resp;
    },
    async deleteUserMultiple(user_id,store){
        var resp = await VueStoreService.delete("user/multi-project/"+user_id,store)
        return resp;
    },
    async getUsersByProject(project_id,store){
        var resp = await VueStoreService.get("user/searchByProject/"+project_id, store)
        return resp;
    },  
}

export default UserService;