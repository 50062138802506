/* eslint-disable*/
import VueStoreService from './VueStoreService'
const RestrictionsService = {
  async getRestriccionesReport(url, store) {
    var resp = await VueStoreService.get('restriction/all' + url, store)
    return resp
  },
  async getValuesKpi(store) {
    var resp = await VueStoreService.get('restriction/graph-optimization', store)
    return resp
  },
  async getCountBubble(filter) {
    var resp = await VueStoreService.get2('restriction/getBubble' + filter)
    return resp
  },
  async getRestricciones(url, store) {
    var resp = await VueStoreService.get('restriction' + url, store)
    return resp
  },
  async getRestriccionesProv(url, store) {
    var resp = await VueStoreService.get('restriction/provisionales' + url, store)
    return resp
  },
  async getRestriccionById(id, store) {
    var resp = await VueStoreService.get('restriction/' + id, store)
    return resp
  },
  async saveRestricciones(data, store) {
    var resp = await VueStoreService.post('restriction', data, store)
    return resp
  },
  async updateRestricciones(rest_id, data, store) {
    var resp = await VueStoreService.put('restriction/' + rest_id, data, store)
    return resp
  },

  async deleteRestriccion(rest_id, store) {
    var resp = await VueStoreService.delete('restriction/' + rest_id, store)
    return resp
  },
  async deleteWithComment(data, store) {
    var resp = await VueStoreService.deleteWithData('restriction/deleteWithComment', data, store)
    return resp
  },

  //ALERTAS
  async getAlertas(project_id, store) {
    var resp = await VueStoreService.get('restriction/alert/' + project_id, store)
    return resp
  },
  //IMPORTAR
  async saveImport(data, store) {
    var resp = await VueStoreService.post('restriction/upload', data, store)
    return resp
  },
  //IMPORTAR  PROVISIONALES
  async saveImportProvisional(data, store) {
    var resp = await VueStoreService.post('restriction/upload-provisional', data, store)
    return resp
  },
  //IMPORTAR
  async exportExcel(id, store) {
    var resp = await VueStoreService.getFile('restriction/download/' + 1, store)
    return resp
  },

  //CAMBIO DE ESTADO
  async saveChangeStatus(data, store) {
    var resp = await VueStoreService.post('restriction/status_masive', data, store)
    return resp
  },

  //COMENTARIOS
  async getComments(rest_id, store) {
    var resp = await VueStoreService.get('history-comment/' + rest_id, store)
    return resp
  },
  async saveComent(data, store) {
    var resp = await VueStoreService.post('history-comment', data, store)
    return resp
  },

  //DATES
  async getDates(rest_id, store) {
    var resp = await VueStoreService.get('history-date/' + rest_id, store)
    return resp
  },

  //GET FILTERS
  async getFilters(viewName, store) {
    var resp = await VueStoreService.get('user-col-2/' + viewName, store)
    return resp
  },

  //SAVE FILTERS
  async saveFilters(data, store) {
    var resp = await VueStoreService.post('user-col-2/', data, store)
    return resp
  },
}

export default RestrictionsService
